import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useUser from 'hooks/useUser';
import { Modal } from '../Modal/Modal';
import { BodyText } from './ExpiringSessionModal.styles';

const WARNING_TIME_MILLISECONDS = 28 * 60 * 1000; // 28 minutes
const SIGN_OUT_TIME_MILLISECONDS = WARNING_TIME_MILLISECONDS + 2 * 60 * 1000; // 2 minutes

type Props = {
  isOpen?: boolean; // only used for storybook
};

export function ExpiringSessionModal({ isOpen: isOpenPropForStorybook = false }: Props) {
  const [isOpen, setIsOpen] = useState(isOpenPropForStorybook);

  // NIST provides a few guidelines for when to expire inactive user sessions.
  // 30 minutes is a good threshold.

  const { t } = useTranslation();
  const warningTimeoutRef = useRef(0);
  const signOutTimeoutRef = useRef(0);

  const { logout } = useUser();

  const warnExpiring = () => {
    setIsOpen(true);
  };

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const setTimeouts = useCallback(() => {
    warningTimeoutRef.current = window.setTimeout(warnExpiring, WARNING_TIME_MILLISECONDS);
    signOutTimeoutRef.current = window.setTimeout(handleLogout, SIGN_OUT_TIME_MILLISECONDS);
  }, [handleLogout]);

  const clearTimeouts = () => {
    clearTimeout(warningTimeoutRef.current);
    clearTimeout(signOutTimeoutRef.current);
  };

  useEffect(() => {
    const resetTimeouts = () => {
      clearTimeouts();
      setTimeouts();
    };

    setTimeouts();
    window.addEventListener('click', resetTimeouts);
    window.addEventListener('scroll', resetTimeouts);
    window.addEventListener('keydown', resetTimeouts);

    return () => {
      window.removeEventListener('click', resetTimeouts);
      window.removeEventListener('scroll', resetTimeouts);
      window.removeEventListener('keydown', resetTimeouts);
      clearTimeouts();
    };
  }, [setTimeouts]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('expiringSession.inactiveSession')}
      contentLabel={`${t('expiringSession.inactiveSession')} Modal`}
      shouldCloseOnOverlayClick={false}
      showCloseButton
      shouldCloseOnEsc={false}
      isFullWidth
      styleVariant="tertiary"
      onRequestClose={() => setIsOpen(false)}
      ctaButtons={{
        tertiary: {
          label: t('expiringSession.logOut'),
          onClick: handleLogout,
        },
        primary: {
          label: t('expiringSession.imStillHere'),
          onClick: () => setIsOpen(false),
        },
      }}
    >
      <BodyText as="p">{t('expiringSession.areYouStillThere')} </BodyText>
    </Modal>
  );
}

export default ExpiringSessionModal;
