export const perSessionBannersKey = 'perSessionBanners';

export const perSessionFetchBannersKey = 'perSessionFetchBanners';

export const perSessionModalKey = 'perSessionModalKey';

export const perSessionAchCompletionTimeKey = 'perSessionAchCompletionTimeKey';

export const perSessionTermAgreedKey = 'perSessionTermAgreedKey';

export const perSessionAchDiscountModalM0 = 'achDiscountModalM0';

export const perSessionAchDiscountModalM2 = 'achDiscountModalM2';

export const perSessionAuthCopyAckModal = 'authCopyAckModal';

export const PER_SESSION_STORAGE_KEYS = [
  perSessionBannersKey,
  perSessionFetchBannersKey,
  perSessionModalKey,
  perSessionAchDiscountModalM0,
  perSessionAchDiscountModalM2,
  perSessionAchCompletionTimeKey,
];

export const authenticatedKey = 'authenticated';

// perSessionAutopayBanner is a function because we want a unique key per agreement
export const perSessionAutopayBannerKey = (id: string) => `perSessionAutopayBannerKey-${id}`;
