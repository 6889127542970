import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button/Button';
import { Text } from 'shared/components/Text/Text';
import {
  AutopayTermConditionContainer,
  AutopayTermConditionContent,
  AutopayTermConditionFooter,
  TermConditionItem,
  TermConditionList,
} from './AutopayTermConditionModal.styles';

export type AutopayTermConditionModalProps = {
  primaryLanguage: string;
  onAccept: () => void;
  onDecline: () => void;
};

export function AutopayTermConditionModal({
  primaryLanguage,
  onAccept,
  onDecline,
}: AutopayTermConditionModalProps) {
  const { t, i18n } = useTranslation();
  const bodyList: string[] = t('autopayModal.termConditionBody', {
    returnObjects: true,
  });

  useEffect(() => {
    if (primaryLanguage === 'es') {
      i18n.changeLanguage('es');
    }
  }, [i18n, primaryLanguage]);

  return (
    <AutopayTermConditionContainer>
      <Text as="h2">{t('autopayModal.termCondition')}</Text>
      <AutopayTermConditionContent>
        <Text as="h3" $margin="0 0 20px 0">
          {t('autopayModal.termConditionTitle')}
        </Text>
        <TermConditionList>
          {Array.isArray(bodyList) &&
            bodyList.map((item) => (
              <TermConditionItem>
                <Text as="p">{item}</Text>
              </TermConditionItem>
            ))}
        </TermConditionList>
      </AutopayTermConditionContent>
      <AutopayTermConditionFooter>
        <Button
          label={t('autopayModal.declineButton')}
          styleVariant="secondary"
          onClick={onDecline}
        />
        <Button label={t('autopayModal.acceptButton')} onClick={onAccept} />
      </AutopayTermConditionFooter>
    </AutopayTermConditionContainer>
  );
}

export default AutopayTermConditionModal;
