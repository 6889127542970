import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/components/Text/Text';
import AutopaySuccessIcon from '../../../../shared/components/icons/AutopaySuccessIcon';
import { AutopayBaseContainer } from './AutopayModal.styles';
import { Button } from '../../../../shared/components/Button/Button';

export type AutopaySuccessModalProps = {
  onCompleteFormModal: () => void;
};

export function AutopayAddBankAccountSuccessModal({
  onCompleteFormModal,
}: AutopaySuccessModalProps) {
  const { t } = useTranslation();
  return (
    <AutopayBaseContainer>
      <AutopaySuccessIcon />
      <Text as="h2" $textAlign="center">
        {t('autopayModal.successHeader')}
      </Text>
      <Text as="p">{t('autopayModal.successBody1')}</Text>
      <Text as="p">{t('autopayModal.successBody2')}</Text>
      <Button
        margin="36px 0 0 auto"
        label={t('autopayModal.gotItButton')}
        onClick={onCompleteFormModal}
      />
    </AutopayBaseContainer>
  );
}

export default AutopayAddBankAccountSuccessModal;
