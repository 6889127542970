import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'shared/components/Button/Button';
import { AutopayGraphic } from 'shared/components/icons';
import { Text } from 'shared/components/Text/Text';
import { AutopayBaseContainer } from './AutopayModal.styles';

export type AutopaySetupModalProps = {
  discountEligible: boolean;
  onButtonClick: () => void;
};

export function AutopaySetupModal({ discountEligible, onButtonClick }: AutopaySetupModalProps) {
  const { t } = useTranslation();

  return (
    <AutopayBaseContainer>
      <AutopayGraphic />
      {discountEligible && (
        <>
          <Text as="h2" $textAlign="center">
            {t('autopayModal.setUpAutopay')}
          </Text>
          <Text as="p">
            <Trans>{t('autopayModal.body1')}</Trans>
          </Text>
          <Text as="p">{t('autopayModal.body2')}</Text>
        </>
      )}
      {!discountEligible && (
        <>
          <Text as="h2" $textAlign="center">
            {t('autopayModal.neverMissABill')}
          </Text>
          <Text as="p">{t('autopayModal.goodbyeToPaperChecks')}</Text>
          <Text as="p">{t('autopayModal.safeSecure')}</Text>
        </>
      )}

      <Button
        margin="60px 0 0 auto"
        label={t('autopayModal.acknowledge')}
        onClick={onButtonClick}
      />
    </AutopayBaseContainer>
  );
}

export default AutopaySetupModal;
