import { FlagName } from 'api/types';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { useEffect } from 'react';

declare global {
  interface Window {
    Surfly: any;
    settings: any;
  }
}

export default function SurflyRender() {
  const {
    flags: { [FlagName.surfly]: SurflyFlag },
  } = useFeatureFlags();

  useEffect(() => {
    const useSurfly = document.createElement('script');
    const settings = {
      // Surfly session options can be set here, or at the Company/Plan levels.
      widget_key: 'd1b65024a1154bf9a12c3d896976f474',
    };
    useSurfly.src = '/surflyLink.js';
    useSurfly.defer = true;
    useSurfly.onload = () => {
      const { Surfly } = window;
      if (SurflyFlag) {
        Surfly.init(settings);
      }
    };
    document.body.appendChild(useSurfly);

    return () => {
      document.body.removeChild(useSurfly);
    };
  }, [SurflyFlag]);
  return null;
}
