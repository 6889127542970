import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useWindowSize';
import { Modal } from 'shared/components/Modal/Modal';
import { perSessionModalKey, perSessionTermAgreedKey } from 'constants/localStorageKeys';
import { AutopayModalContent } from './AutopayModal.styles';
import { AutopayTermConditionModal } from './AutopayTermConditionModal';
import { AutopaySetupModal } from './AutopaySetupModal';
import { AutopayAddBankAccountModal } from './AutopayAddBankAccountModal';
import { guessEnvironment } from '../../../../shared/utils/guessEnvironment';
import { getEnv } from '../../../../shared/utils/getEnv';
import { AgreementType } from '../../../../api/types';
import { getPrimaryLanguage } from '../../../../shared/utils/getPrimaryLanguage';

declare global {
  interface Window {
    DocuSign: any;
  }
}

export enum AutoPayStep {
  SETUP_ACH = 1,
  TERM_CONDITION = 2,
  ADD_BANK_ACCOUNT = 3,
}

export type AutopayModalProps = {
  agreement: AgreementType;
  isOpen: boolean;
  discountEligible: boolean;
  modalKey?: string;
  onCloseAutopayModal: () => void;
  onCompleteFormModal: () => void;
  initialStep?: AutoPayStep;
};

export function AutopayModal({
  agreement,
  isOpen,
  discountEligible,
  modalKey,
  onCloseAutopayModal,
  onCompleteFormModal,
  initialStep,
}: AutopayModalProps) {
  const { t } = useTranslation();
  const screenSize = useBreakpoints();
  const [step, setStep] = useState<AutoPayStep>(initialStep || AutoPayStep.SETUP_ACH);

  const docusignRef = useRef<any>(null);

  const integrationKey = getEnv('VITE_APP_DOCUSIGN_INTEGRATION_KEY');

  useEffect(() => {
    const perSessionModalsRaw = localStorage.getItem(perSessionModalKey);

    if (perSessionModalsRaw !== null) {
      const modalsObj = JSON.parse(perSessionModalsRaw);
      // if this modal has a defined item we've already shown it and can preemptively close the modal
      if (!modalKey || (modalKey && modalsObj[modalKey])) {
        onCloseAutopayModal();
      } else {
        localStorage.setItem(perSessionModalKey, JSON.stringify({ [modalKey]: true }));
      }
    }
  }, [onCloseAutopayModal, modalKey]);

  const handleCloseAutopayModal = useCallback(() => {
    setStep(AutoPayStep.SETUP_ACH);
    onCloseAutopayModal();
  }, [onCloseAutopayModal]);

  const loadDocuSignScript = (): Promise<void> =>
    new Promise((resolve, reject) => {
      const docusignScript = document.getElementById('docusignScript');
      if (docusignScript || window.DocuSign) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.id = 'docusignScript';
      script.src =
        guessEnvironment() === 'prod'
          ? 'https://js.docusign.com/bundle.js'
          : 'https://js-d.docusign.com/bundle.js';
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load DocuSign script'));
      document.body.appendChild(script);
    });

  const initializeDocuSign = useCallback(async () => {
    await loadDocuSignScript();
    docusignRef.current = await window.DocuSign.loadDocuSign(integrationKey);
  }, [integrationKey]);

  useEffect(() => {
    initializeDocuSign();
  }, [initializeDocuSign, integrationKey]);

  const onAcceptTerm = () => {
    const perSessionTermAgreedRaw = localStorage.getItem(perSessionTermAgreedKey);
    if (perSessionTermAgreedRaw !== null) {
      const termAgreedObj = JSON.parse(perSessionTermAgreedRaw);
      termAgreedObj[agreement.id] = true;
      localStorage.setItem(perSessionTermAgreedKey, JSON.stringify(termAgreedObj));
    } else {
      localStorage.setItem(perSessionTermAgreedKey, JSON.stringify({ [agreement.id]: true }));
    }
  };

  const renderModalContent = () => {
    if (step === AutoPayStep.SETUP_ACH) {
      return (
        <AutopaySetupModal
          discountEligible={discountEligible}
          onButtonClick={() => {
            let nextStep = AutoPayStep.TERM_CONDITION;
            const perSessionTermAgreedRaw = localStorage.getItem(perSessionTermAgreedKey);

            if (perSessionTermAgreedRaw !== null) {
              const termAgreedObj = JSON.parse(perSessionTermAgreedRaw);
              if (termAgreedObj && termAgreedObj[agreement.id]) {
                nextStep = AutoPayStep.ADD_BANK_ACCOUNT;
              }
            }
            setStep(nextStep);
          }}
        />
      );
    }

    if (step === AutoPayStep.TERM_CONDITION) {
      return (
        <AutopayTermConditionModal
          primaryLanguage={getPrimaryLanguage(agreement.primary_language)}
          onAccept={() => {
            onAcceptTerm();
            setStep(AutoPayStep.ADD_BANK_ACCOUNT);
          }}
          onDecline={() => {
            setStep(AutoPayStep.SETUP_ACH);
          }}
        />
      );
    }

    return (
      <AutopayAddBankAccountModal
        agreementId={agreement.id}
        onCompleteFormModal={onCompleteFormModal}
        onCloseModal={handleCloseAutopayModal}
        docusignRef={docusignRef}
      />
    );
  };

  return (
    <Modal
      contentLabel={
        discountEligible ? t('autopayModal.setUpAutopay') : t('autopayModal.neverMissABill')
      }
      styleVariant="tertiary"
      isOpen={isOpen}
      subHeader
      isFullWidth={screenSize === 'sm'}
      fitContent
      padding="24px"
      onRequestClose={handleCloseAutopayModal}
    >
      <AutopayModalContent>{renderModalContent()}</AutopayModalContent>
    </Modal>
  );
}

export default AutopayModal;
