import { useEffect } from 'react';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { FlagName } from 'api/types';

declare global {
  // This interface is, in fact, used (see inContact.onload below)
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    icPatronChat: any;
    BrandEmbassy: any;
    brandembassy: {
      (command: string, ...args: any[]): void;
      q?: any[];
    };
    hasChatInitialized: boolean;
  }
}

export default function InContact() {
  const {
    flags: { [FlagName.updatedInContact]: updatedInContact },
  } = useFeatureFlags();

  useEffect(() => {
    if (!updatedInContact) {
      const inContact = document.createElement('script');
      inContact.src = '/incontact.20240123.js';
      inContact.defer = true;
      inContact.onload = () => {
        // InContact must be initialized once and exactly once, or an ominous
        // shadow appears on the page where the chat box ought to be for
        // every invocation.
        const isInitialized =
          document.getElementsByClassName('ie-div-position-customer-chat').length === 0;
        if (isInitialized) {
          const { icPatronChat } = window;
          // It would be better practice to extract the "special" keys from here
          // into .env but it's not totally clear which of these are actually special.
          icPatronChat.init({
            serverHost: 'https://home-c63.nice-incontact.com',
            bus_no: 4606227,
            poc: '9f67020b-a67d-400c-aabb-c5ab3a1e8bc4',
            params: ['FirstName', 'Last Name', 'first.last@company.com', '555-555-5555'],
          });
        }
      };
      document.body.appendChild(inContact);

      return () => {
        document.body.removeChild(inContact);
      };
    }

    const brandEmbassyScript = document.createElement('script');
    if (!window.hasChatInitialized) {
      const url = 'https://livechat-static-de-na1.niceincontact.com/4/chat.js';
      brandEmbassyScript.src = `${url}?${Math.round(Date.now() / 1e3 / 3600)}`;
      brandEmbassyScript.defer = true;

      window.BrandEmbassy = 'brandembassy';
      window[window.BrandEmbassy] =
        window[window.BrandEmbassy] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (window.brandembassy.q = window.brandembassy.q || []).push(arguments);
        };
      window.brandembassy('init', 3536, 'chat_98192d76-e01b-4e18-8145-02aa6bac379b');
      window.hasChatInitialized = true;
      window.brandembassy('onPushUpdate', ['MessageCreated'], (e: any) => {
        if (e.data.message.isRead) {
          const iframe = document.querySelector(
            'iframe[id^="BrandEmbassy-iframe"]',
          ) as HTMLIFrameElement;
          if (iframe?.contentWindow) {
            const waitingQueueModal = iframe.contentWindow.document.querySelector(
              '*[class^="WaitingQueueModal"]',
            ) as HTMLElement;
            if (waitingQueueModal) {
              waitingQueueModal.style.display = 'none';
            }
          }
        }
      });
    }

    document.head.appendChild(brandEmbassyScript);

    return () => {
      document.head.removeChild(brandEmbassyScript);
    };
  }, [updatedInContact]);

  return null;
}
