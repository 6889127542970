import styled from 'styled-components';

import { Text } from '../Text/Text';

export const BodyText = styled(Text)`
  font-weight: 400;
  margin: 16px 0;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;

export default BodyText;
