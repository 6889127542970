import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../Modal/Modal';
import { CtaButtons } from '../Modal/types';
import { WarningText } from './UnsavedChanges.styles';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onLeave: () => void;
};

export function UnsavedChangesModal({ isOpen, onCancel, onLeave }: Props) {
  const { t } = useTranslation();

  const ctaButtons: CtaButtons = {
    tertiary: {
      label: t('cta.cancel'),
      onClick: onCancel,
    },
    permanentPrimary: {
      label: t('unsavedChanges.leave'),
      onClick: onLeave,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('unsavedChanges.unsavedChanges')}
      contentLabel=""
      styleVariant="tertiary"
      onRequestClose={onCancel}
      ctaButtons={ctaButtons}
    >
      <WarningText as="p">{t('unsavedChanges.changesWillBeLost')}</WarningText>
    </Modal>
  );
}

export default UnsavedChangesModal;
