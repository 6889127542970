import React from 'react';
import { useTheme } from 'styled-components';

import houseLight from 'static/house.light-mode.svg';
import houseDark from 'static/house.dark-mode.svg';
import HouseGraphicImage from './HouseGraphic.styles';

export function HouseGraphic() {
  const theme = useTheme();
  const asset = theme.name === 'darkTheme' ? houseDark : houseLight;
  return <HouseGraphicImage src={asset} />;
}

export default HouseGraphic;
