import styled from 'styled-components';

export const AutopayAddBankAccountContainer = styled.div`
  height: 650px;
  width: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AutopaySigningSection = styled.div<{ $isSigningReady: boolean }>`
  width: ${({ $isSigningReady }) => ($isSigningReady ? '100%' : 'auto')};
  height: ${({ $isSigningReady }) => ($isSigningReady ? '100%' : 'auto')};
`;
