import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router';

import useUser from 'hooks/useUser';
import { Header } from 'shared/components/Header/Header';
import { UnsavedChangesModal } from 'shared/components/UnsavedChangesModal/UnsavedChangesModal';
import { MilestoneType } from 'modules/types';
import {
  AccountNumber,
  AccountWrapper,
  ContactSection,
  LoginSection,
  SectionTitle,
} from './Account.styles';
import { ContactForm } from '../components/ContactForm/ContactForm';
import { LoginDetailsForm } from '../components/LoginDetailsForm/LoginDetailsForm';
import { ContactCard } from '../components/ContactCard/ContactCard';
import { LoginCard } from '../components/LoginCard/LoginCard';

export function Account() {
  const [updateContactInfo, setUpdateContactInfo] = useState(false);
  const [updateCredentials, setUpdateCredentials] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { t } = useTranslation();
  const { userResult, selectedAccount, refetch } = useUser();
  const user = userResult?.user;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isFormDirty && currentLocation.pathname !== nextLocation.pathname,
  );

  const canEditAccountInfo = Boolean(
    selectedAccount?.agreements.every(
      (agreement) =>
        agreement.current_milestone === MilestoneType.IN_SERVICE ||
        agreement.current_milestone === null,
    ),
  );

  const showContactForm = () => {
    setUpdateContactInfo(true);
  };

  const hideContactForm = () => {
    setIsFormDirty(false);
    setUpdateContactInfo(false);
  };

  const showLoginForm = () => {
    setUpdateCredentials(true);
  };

  const hideLoginForm = () => {
    setIsFormDirty(false);
    setUpdateCredentials(false);
  };

  const handleOnSave = async () => {
    refetch();
    hideContactForm();
  };

  const handleLeave = () => {
    blocker.proceed?.();
  };

  const handleCancel = () => {
    blocker.reset?.();
  };

  return (
    <>
      <AccountWrapper>
        <Header pageName="ACCOUNT" title={t('account.account')} />

        {user ? (
          <>
            <AccountNumber as="h3">#{selectedAccount?.homeowner_account_id}</AccountNumber>

            <ContactSection>
              <SectionTitle as="h3">{t('account.contactInformation')}</SectionTitle>
              {updateContactInfo ? (
                <ContactForm
                  user={user}
                  onCancel={hideContactForm}
                  onSave={handleOnSave}
                  showAccountInfo={canEditAccountInfo}
                  onFieldChange={setIsFormDirty}
                />
              ) : (
                <ContactCard user={user} onEdit={showContactForm} />
              )}
            </ContactSection>

            <LoginSection>
              <SectionTitle as="h3">{t('account.loginInformation')}</SectionTitle>

              {updateCredentials ? (
                <LoginDetailsForm
                  user={user}
                  onCancel={hideLoginForm}
                  onSave={hideContactForm}
                  onFieldChange={setIsFormDirty}
                />
              ) : (
                <LoginCard user={user} onEdit={showLoginForm} />
              )}
            </LoginSection>
          </>
        ) : null}
      </AccountWrapper>
      <UnsavedChangesModal
        isOpen={blocker.state === 'blocked'}
        onCancel={handleCancel}
        onLeave={handleLeave}
      />
    </>
  );
}

export default Account;
