import React from 'react';
import { RouterProvider } from 'react-router-dom';

import 'legacy/AppDependentRoutes'; // TODO: remove after admin reskin is merged
import GlobalStyles from 'GlobalStyles';
import { router } from 'routes/router';
import { ThemeLayout } from 'layouts/ThemeLayout';

export function App() {
  return (
    <>
      <GlobalStyles />
      <ThemeLayout>
        <RouterProvider router={router} />
      </ThemeLayout>
    </>
  );
}

export default App;
