import styled from 'styled-components';

import { Text } from '../Text/Text';

export const WarningText = styled(Text)`
  font-family: Avenir;
  font-weight: 400;
  margin: 32px 0;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 620px;
  }
`;

export default WarningText;
