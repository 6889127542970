import { MilestoneType, WelcomeChecklistStatusType, WelcomeChecklistType } from 'modules/types';
import { PrimaryLanguage, RolesKey, SentEmailType } from 'modules/types/util';

export interface Contract {
  presigned_url: string;
}

export interface AccountType {
  id: string;
  homeowner_account_id: string;
  address: AddressType;
  agreements: AgreementType[];
  archived: boolean;
}

export interface AddressType {
  id: string;
  street_1: string;
  street_2: string;
  city: string;
  state: string;
  zip_code: string;
  archived: boolean;
}

export enum ContractType {
  LOA = 'LOA',
  LSE = 'LSE',
  PPA = 'PPA',
}

export interface AgreementType {
  id: string;
  current_milestone: MilestoneType;
  account: string;
  account_users_summaries: { id: string; email: string; name: string; claimed: boolean }[];
  ach_discount: number | undefined;
  ach_discount_eligible: boolean;
  ach_elected: boolean;
  amount_financed: number | null;
  buydown_amount: number;
  commencement_date: Date | null;
  contract_signed_date: string;
  contract_start_date: string;
  contract_term: number | undefined;
  contract_type: string;
  current_balance: number;
  first_payment_date: Date | null;
  homeowner_contract_id: string;
  in_service_date: Date | null;
  initial_balance: number;
  interest_rate: number | null;
  is_ric: boolean;
  is_beam: boolean;
  great_america_application_ids: number[];
  monthly_payment: number | undefined;
  number_of_payments: number;
  platform_uuid: string;
  pto_date: Date | null;
  product_display_name: string;
  product_name: string;
  product_type: string;
  product_version: number | null;
  reamortization_dates: [Date];
  rate_contract: number | undefined;
  rate_escalator: number | null;
  system: SystemType;
  archived: boolean;
  welcome_checklists: WelcomeChecklistType[];
  welcome_checklist_upload_subtask_id: string | null;
  welcome_checklist_complete_subtask_id: string | null;
  welcome_checklist_status: WelcomeChecklistStatusType;
  organization: OrganizationType | null;
  primary_language: PrimaryLanguage;
  authoritative_copy_acknowledged: boolean;
}

export interface BatteryType {
  id: string;
  manufacturer: string;
  model: string;
  capacity: number;
  power_rating: number;
  count: number;
  archived: boolean;
}

export type LoginRequestBody = {
  email: string;
  password: string;
};

export interface OrganizationType {
  id: string;
  organization_id: string;
  name: string;
  logo_image_url?: string;
  website_url: string;
}

export interface SystemType {
  id: string;
  panels: string | undefined;
  inverters: string | undefined;
  capacity: number | undefined;
  estimated_offset: string | undefined;
  system_mounting_type: string | undefined;
  inverter_manufacturer_id: string | undefined;
  site_id: string | undefined;
  install_cost: number;
  inverter_manufacturer_monitoring_id: string | undefined;
  address: AddressType;
  batteries: BatteryType[];
  archived: boolean;
  has_solar_monitoring: boolean;
  has_battery_monitoring: boolean;
  has_home_monitoring: boolean;
  has_grid_monitoring: boolean;
  has_ct: boolean;
  site_timezone: string | undefined;
  total_battery_capacity_kwh: number | null;
}

export interface UserType {
  id: string;
  address: AddressType;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  role: RolesKey;
  claimed_account: boolean;
  date_joined: string;
  accounts: AccountType[];
  archived: boolean;
  claimed_date: string;
  sent_emails: SentEmailType[];
  last_password_reset_date: string;
  last_login: string;
}

export interface TagType {
  id: string;
  tag: string;
}

export interface FaqType {
  id: string;
  question: string;
  answer: string;
  hidden: boolean;
  order: number;
  tags: TagType[];
  url_slug: string;
}

export enum FlagName {
  webV2_1 = 'Web V2 (2.1) - Active',
  createBrowserRouter = 'Create Browser Router - Active',
  achModal = 'ACH Modal - Active',
  webDarkTheme = 'Web Dark Theme',
  adminV2 = 'Admin V2 - Active',
  updatedInContact = 'InContact',
  surfly = 'Surfly',
}

export type FeatureFlag = {
  archived: boolean;
  date_created: string;
  date_updated: string;
  enabled: boolean;
  id: string;
  name: FlagName;
};

export {
  DateRangeZoomLevel,
  BatteryStatusValue,
  BucketSize,
  ChartQueryType,
  GridStatusValue,
  SolarEdgeStatusValue,
} from './system/types';
export type {
  EnergyUnit,
  MonitoringDataParams,
  MonitoringDatum,
  PowerFlowDataResponse,
  PowerFlowDatum,
  PowerUnit,
  SiteTimezoneResponse,
} from './system/types';

export type {
  EnergyAllocationType,
  EnergySummaryType,
  MonitoringResponseType,
  RawMonitoringDatumType,
} from './system/monitoringTypes';
