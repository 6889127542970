import styled from 'styled-components';

const HouseGraphicImage = styled.img`
  height: 280px;
  align-self: flex-start;
  margin: -40px 0 0 40px;
  width: 'auto';
  align-self: flex-start;
`;

export default HouseGraphicImage;
