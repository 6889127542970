import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useAcknowledgeAuthCopy } from 'api/agreement';
import { AccountType } from 'api/types';
import { perSessionModalKey } from 'constants/localStorageKeys';
import { authCopyAckNeeded } from './utils/authCopyAckNeeded';
import { BodyWrapper } from './AuthCopyAckModal.styles';
import { Modal } from '../Modal/Modal';
import { AuthCopyAckBody } from './AuthCopyAckBody/AuthCopcyAckBody';
import { ErrorBody } from './ErrorBody/ErrorBody';
import { ConfirmedBody } from './ConfirmedBody/ConfirmedBody';
import { CtaButton } from '../Modal/types';
import { toast } from '../Toast/Toast';
import { WarningText } from '../UnsavedChangesModal/UnsavedChanges.styles';

type Props = {
  modalKey?: string;
  account?: AccountType;
};

export function AuthCopyAckModal({ account, modalKey }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [showConfirmed, setShowConfirmed] = useState(false);
  const [modalBodyError, setModalBodyError] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();

  const { mutate: acknowledgeAuthCopy, isLoading } = useAcknowledgeAuthCopy({
    onSuccess: () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('acknowledgeAuthoritativeCopy.acknowledgedSuccessfully'),
        theme,
      });
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('acknowledgeAuthoritativeCopy.unableToSave'),
        theme,
      });

      setModalBodyError(true);
    },
  });

  useEffect(() => {
    const perSessionModalsRaw = localStorage.getItem(perSessionModalKey);
    const { isNeeded } = authCopyAckNeeded(account);

    if (!isNeeded) return; // we don't need to show the modal

    if (perSessionModalsRaw != null && modalKey) {
      const modalsObj = JSON.parse(perSessionModalsRaw);
      const savedModalVisible =
        modalsObj[modalKey] === undefined ? (modalsObj[modalKey] = true) : modalsObj[modalKey];

      localStorage.setItem(perSessionModalKey, JSON.stringify(modalsObj));
      setIsOpen(savedModalVisible);
    } else if (modalKey) {
      localStorage.setItem(perSessionModalKey, JSON.stringify({ [modalKey]: true }));
      setIsOpen(true);
    }
  }, [modalKey, account]);

  const handleAcknowledge = async () => {
    setMessage(null);

    if (!confirmed) {
      setMessage(t('acknowledgeAuthoritativeCopy.acknowledgeCopy.checkboxError'));
      return;
    }

    const { isNeeded, agreements } = authCopyAckNeeded(account);
    if (isNeeded) {
      agreements.map((agreement) => acknowledgeAuthCopy(agreement.id));

      setShowConfirmed(true);
    }
  };

  const handleChangeConfirm = () => {
    setConfirmed((c) => !c);
  };

  const handleClose = () => {
    const perSessionModalsRaw = localStorage.getItem(perSessionModalKey);
    if (perSessionModalsRaw != null && modalKey) {
      const modalsObj = JSON.parse(perSessionModalsRaw);
      modalsObj[modalKey] = false;
      localStorage.setItem(perSessionModalKey, JSON.stringify(modalsObj));
    }

    setIsOpen(false);
  };

  const handleShowWarningModal = () => {
    if (confirmed && !modalBodyError && !showConfirmed) {
      setShowWarningModal(true);
    } else {
      handleClose();
    }
  };

  const generateCtaButtons = () => {
    if (showWarningModal) {
      return {
        tertiary: {
          label: t('cta.cancel'),
          onClick: () => {
            setShowWarningModal(false);
          },
        },
        permanentPrimary: {
          label: t('unsavedChanges.leave'),
          onClick: handleClose,
        },
      };
    }
    const primary: CtaButton = {
      label: showConfirmed ? t('cta.close') : t('cta.submit'),
      isLoading,
      onClick: showConfirmed ? handleClose : handleAcknowledge,
    };

    const tertiary: CtaButton = {
      label: t('cta.cancel'),
      onClick: handleShowWarningModal,
    };

    if (modalBodyError) {
      primary.label = t('cta.tryAgain');
      primary.onClick = handleAcknowledge;
    }

    return {
      tertiary: showConfirmed ? undefined : tertiary,
      primary,
    };
  };

  let modalBody = showConfirmed ? (
    <ConfirmedBody />
  ) : (
    <AuthCopyAckBody
      onChangeConfirm={handleChangeConfirm}
      confirmed={confirmed}
      checkboxErrorMessage={message}
    />
  );

  if (showWarningModal) {
    modalBody = <WarningText as="p">{t('unsavedChanges.changesMayBeLost')}</WarningText>;
  }

  if (modalBodyError) {
    modalBody = <ErrorBody />;
  }

  return (
    <Modal
      isOpen={isOpen}
      title={
        showWarningModal
          ? t('unsavedChanges.unsavedChanges')
          : t('acknowledgeAuthoritativeCopy.title')
      }
      contentLabel=""
      styleVariant="tertiary"
      onRequestClose={handleShowWarningModal}
      ctaButtons={generateCtaButtons()}
    >
      <BodyWrapper>{modalBody}</BodyWrapper>
    </Modal>
  );
}

export default AuthCopyAckModal;
