import styled from 'styled-components';

export const AutopayTermConditionContainer = styled.div`
  width: 620px;
  text-align: left;
`;

export const AutopayTermConditionContent = styled.div`
  overflow-y: scroll;
  height: 400px;
  text-align: justify;
  color: #464646;
`;

export const AutopayTermConditionFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 16px;
  margin-top: 36px;
`;

export const TermConditionList = styled.ol`
  padding-left: 24px;
`;

export const TermConditionItem = styled.li`
  font-size: medium;
  font-weight: 100;
  margin-bottom: 20px;
`;
