import React, { useState } from 'react';
import DataDogLogs from 'shared/logging/DataDogLogs';
import { useTheme } from 'styled-components';
import { LINKS } from 'shared/links';
import { useTranslation } from 'react-i18next';
import usePageName from 'hooks/usePageName';
import { FlagName } from 'api/types';
import useFeatureFlags from 'hooks/useFeatureFlags';
import {
  CopyRightText,
  DividerStyled,
  FooterStyled,
  MenuLink,
  MenuWrapper,
  ScreenCastWrapper,
  ScreenShareWrapper,
  StoreButtons,
} from './Footer.styles';
import { AppStoreIconLarge, GooglePlayIconLarge } from '../icons';
import { ScreenCastIcon } from '../icons/ScreenCastIcon';
import { SurflyErrorModal } from '../SurflyErrorModal/SurflyErrorModal';
import { EverBrightLogo } from '../icons/EverBrightLogo';

export function Footer() {
  const theme = useTheme();
  const { t } = useTranslation();

  const { currentPage } = usePageName();
  const {
    flags: { [FlagName.surfly]: Surfly },
  } = useFeatureFlags();
  const [openModal, setOpenModal] = useState(false);
  const handleClick = () => {
    if (window.Surfly && !window.Surfly.isInsideSession) {
      window.Surfly.session().startLeader();
    } else {
      DataDogLogs.error('Footer', {
        additionalInfo: 'Surfly is not initialized',
      });
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleRefresh = () => window.location.reload();

  return (
    <>
      <DividerStyled />
      <FooterStyled>
        {currentPage === 'AUTH' && <EverBrightLogo />}
        <MenuWrapper>
          {LINKS.FOOTER_MENU_OPTIONS.map(({ text, link }) => (
            <MenuLink key={text} href={link} target="_blank" rel="noreferrer">
              {text}
            </MenuLink>
          ))}
        </MenuWrapper>

        {currentPage === 'SUPPORT' && Surfly ? (
          <ScreenCastWrapper onClick={handleClick}>
            <ScreenCastIcon color={theme.colors.buttonButtonPrimarySecondary2100} />
            <ScreenShareWrapper> {t('share')} </ScreenShareWrapper>
          </ScreenCastWrapper>
        ) : (
          <StoreButtons>
            <a
              href={LINKS.MOBILE_APP_STOREFRONTS.apple}
              target="_blank"
              rel="noreferrer"
              aria-label="apple store icon"
            >
              <AppStoreIconLarge color={theme.colors.iconIconSecondaryGrey90} />
            </a>
            <a
              href={LINKS.MOBILE_APP_STOREFRONTS.google}
              target="_blank"
              rel="noreferrer"
              aria-label="google play store icon"
            >
              <GooglePlayIconLarge color={theme.colors.iconIconSecondaryGrey90} />
            </a>
          </StoreButtons>
        )}
      </FooterStyled>
      <CopyRightText as="p">{t('copyright', { year: new Date().getFullYear() })}</CopyRightText>
      <SurflyErrorModal
        openModal={openModal}
        onCloseModal={handleCloseModal}
        onRefresh={handleRefresh}
      />
    </>
  );
}

export default Footer;
