/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useTheme } from 'styled-components';
import { AxiosError } from 'axios';
import { useBlocker } from 'react-router';

import DataDogLogs from 'shared/logging/DataDogLogs';
import { postComment } from 'api/support';
import { PHONE_NUMBERS } from 'constants/phoneNumbers';
import { Button } from 'shared/components/Button/Button';
import { TextArea } from 'shared/components/TextArea/TextArea';
import { PhoneIcon } from 'shared/components/icons';
import { Divider } from 'shared/components/Divider/Divider';
import { formatPhoneNumber } from 'shared/utils/formatPhoneNumber';
import { toast } from 'shared/components/Toast/Toast';
import { UnsavedChangesModal } from 'shared/components/UnsavedChangesModal/UnsavedChangesModal';
import { BodyText, CallUsWrapper, Form, TextWrapper, Title } from './SupportForm.styles';

interface IFormInput {
  message: string;
}

type Props = {
  email: string;
};

export function SupportForm({ email }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    getValues,
  } = useForm<IFormInput>();

  const watchMessage = useWatch({
    control,
    name: 'message',
  });

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      Boolean(watchMessage) && currentLocation.pathname !== nextLocation.pathname,
  );

  const { mutate, isLoading } = useMutation({
    mutationFn: postComment,
    onSuccess: () => {
      toast({
        type: 'success',
        title: t('toast.success'),
        message: t('support&FAQs.emailSent'),
        theme,
      });
      reset();
    },
    onError: (error: AxiosError) => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('support&FAQs.emailNotSent'),
        theme,
      });

      DataDogLogs.error(
        'Support - SupportForm',
        {
          additionalInfo: {
            formData: getValues(),
          },
        },
        error,
      );
    },
  });

  const handleClickCall = () => {
    window.location.href = `tel:${PHONE_NUMBERS.CUSTOMER_SUPPORT}`;
  };

  const onSubmit: SubmitHandler<IFormInput> = ({ message: comment }) => {
    if (!comment.trim()) {
      // Simple validation to prevent empty comments
      setError('message', { type: 'required', message: t('support&FAQs.required') });
      return;
    }
    mutate({ comment });
  };

  const handleLeave = () => {
    blocker.proceed?.();
  };

  const handleCancel = () => {
    blocker.reset?.();
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title as="h3">{t('support&FAQs.howCanWeHelp')}</Title>

        <BodyText as="p">
          <Trans
            i18nKey="support&FAQs.weWillContactYou"
            values={{ email }}
            components={{
              strong: <BodyText as="p" $isBold />,
            }}
          />
        </BodyText>

        <BodyText as="p" className="you-will-re">
          {t('support&FAQs.youWillReceiveAnEmail')}
        </BodyText>

        <TextArea
          id="support-form-message"
          ariaLabel={t('support&FAQs.message')}
          label={t('support&FAQs.message')}
          placeholder={`${t('support&FAQs.howCanWeHelp')}...`}
          hasError={Boolean(errors.message)}
          errorMessage={errors.message?.message}
          {...register('message', { required: t('support&FAQs.required') })}
        />

        <Button
          dataTestId="send-email-button"
          type="submit"
          label={t('support&FAQs.sendEmail')}
          isDisabled={isLoading}
          margin="8px 0 0"
        />

        <CallUsWrapper>
          <BodyText as="p" $margin="0 12px">
            {t('support&FAQs.orCallUs')}
          </BodyText>
          <Divider />
        </CallUsWrapper>

        <Button
          dataTestId="call-support-button"
          styleVariant="secondary"
          label={formatPhoneNumber(PHONE_NUMBERS.CUSTOMER_SUPPORT)}
          Icon={PhoneIcon}
          onClick={handleClickCall}
        />

        <TextWrapper>
          <BodyText as="p">{t('support&FAQs.supportWeekDayAvailability')}</BodyText>
          <BodyText as="p">{t('support&FAQs.supportWeekendAvailability')}</BodyText>
        </TextWrapper>
      </Form>
      <UnsavedChangesModal
        isOpen={blocker.state === 'blocked'}
        onCancel={handleCancel}
        onLeave={handleLeave}
      />
    </>
  );
}
export default SupportForm;
